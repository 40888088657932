header {


	.logo-top {

		.container {
			text-align: center;
    		padding: 30px;
		}
	}

	.nav .open > a {
   		 background-color:transparent;
	}


	.navbar {
		min-height: 38px;
	    background-color: transparent;
	    text-align: center;
	    border-top: 1px solid white;
	    border-bottom: 1px solid white;
	    margin: 0 auto;
	    width: 100%;
	    z-index: 999999;
	    transition: all 0.5s ease;
		position: absolute;
		background-color: rgb(49,103,144);
		@include breakpoint (sm) {
			position: fixed!important;
			top: 0;
		}
		@include breakpoint (xs) {
			min-height: 80px;
		}

		.navbar-nav {
		    display: inline-block;
		    float: none;
		    margin-bottom: -4px;
   			margin-top: -1px;

		    li {

				a {
					font-size: 14px;
					font-weight: 400;
				    color: white;
				    letter-spacing: 1px;
				    outline: none;
				    text-transform: uppercase;
				    transition: all 0.5s ease;
				    padding: 15px 20px;
				    background-image: none!important;
					
					&:hover {
						color: white;
						background-color: transparent!important;
						transition: all 0.5s ease;
						outline: none;
					
					}

					&:focus {

						background-color: transparent!important;
						outline: none;
					}
					&:visited {

						background-color: transparent!important;
						outline: none;
					}

					@include breakpoint (md) {
						font-size: 12px;
					}

					@include breakpoint (xs) {
						text-align: center;
						font-size: 12px;
						padding:15px 0;
				
					}


				}

				.active {
					border-bottom: 2px solid $orange;

					@include breakpoint (sm) {
						display: inline-block;
					}
				}
	
				
			}

		@include breakpoint (sm) {
			margin: 23px 0;
		}

		.dropdown-menu {
			background-color: $nav;
			border: 1px solid white;
			border-radius: 0px;
			-webkit-box-shadow: 0;
			box-shadow: 0px;
			visibility: hidden; /* hides sub-menu */
			opacity: 0;
			position: absolute;
			top: 100%;
			left: 0;
			transform: translateY(-2em);
			z-index: -1;
			transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;

			@include breakpoint (sm) {
				position: relative;
				float: none;
				box-shadow: none;
				width: 100%;
				border: 0px;
				transform: translateY(0%);
				position:relative;
				opacity: 1;
				visibility: visible;

			}

			@include breakpoint (xs) {
				padding:10px;
				transform: translateY(0%);
				position:relative;
				opacity: 1;
				visibility: visible;
			}
		}
		.dropdown-menu li {

			a {
				padding: 6px 20px;
			}

			@include breakpoint (md) {
				text-align: center;
			}

			@include breakpoint (sm) {
				padding:10px;
			}
		}
		.dropdown:hover .dropdown-menu {

		  visibility: visible; 
		  opacity: 1;
		  z-index: 1;
		  transform: translateY(0%);
		  transition-delay: 0s, 0s, 0.3s;
		  box-shadow:none!important; 

			li {

				a {
					transition: all 0.5s ease;	

					&:after {
						height: 0px!important;
					}
					&:before {
						height: 0px!important;
					}
				}

				&:hover {
					background-color:$orange;
					transition: all 0.5s ease;
				}	
			}
		}

		}
		.navbar-brand {
		  transform: translateX(-50%);
		  left: 50%;
		  top:-70px;
		  position: absolute;
		  padding: 0px;

			img {
			  height: 100%;
			  width: auto;
			  padding: 7px 14px;
			}

			@include breakpoint (sm) {
				top:0;
			}

			@include breakpoint (xs) {
				top:8px;
			}

		}
		.navbar-header {
			.icon-bar {
				background-color: white;
				height: 2px;
			}	
		}
		.navbar-collapse {
		    text-align: center;
		}

		.logo-sticky-nav {
			display: none;
		}


	}

	.navbar_active {
		background-color: $nav;
		@include respond-to(992){
			transition: all 0.5s ease;
		    position: fixed;
		    top: 0;
		    padding: 10px 10px 1px 10px;

		    .logo-sticky-nav {
				display: inline-block;
				float: left;

				img {
					height: 35px;
				}
			}
		}
		@include breakpoint(sm){
			position: fixed;
		}
	}

	.navbar-header {
		@include transition(linear, .28s);   
	}

  .navbar-mobile {
  	height: 60px;

	  @include transition(linear, .28s);

	   .navbar-header {
	      .navbar-brand {

	        img {

	          // width: 140px;

	          @include transition(linear, .28s);


	        }
	    
	      }
	  }
   @include breakpoint (md)  {
     height: 60px!important;
   }
   
}

.navbar-collapse.collapse {
	box-shadow: none;
	border-top: 0px solid transparent;
}


.navbar-toggle {
  background-color: transparent;
  margin-top: 20px;
  box-shadow: none;

  .icon-bar {
    background-color:white;
  }

  @include breakpoint (sm)  {
    width: 44px!important;
  }

}

	@media (max-width: 992px) {
	  .affix {
	    display: static;
	  }
	  .navbar-header {
	    float: none;
	  }
	  .navbar-left, .navbar-right {
	    float: none!important;
	  }
	  .navbar-toggle {
	    display: block;
	  }
	  .navbar-collapse {
	    border-top: 0px solid transparent;
	  }
	  .navbar-fixed-top {
	    top: 0;
	    border-width: 0 0 1px;
	  }
	  .navbar-collapse.collapse {
	    display: none!important;
	    border-top: 0px solid transparent;
	  }
	  .navbar-nav {
	    float: none!important;
	    margin-top: 20px;
	    width: 100%;

	    > li {
	      float: none;
	      > a {
	        padding-top: 10px;
	        padding-bottom: 10px;
	      }
	    }


	  }

	  .collapse.in {
	    display: block!important;
	    border-top: 0px solid transparent;
	   
	  }
	}

	 @include breakpoint (xs) {
	  	.navbar-collapse {
	      	border-top: 0px solid transparent;
		}
		.collapse.in {
	    	 border-top: 0px solid transparent;
	  	}
	}

	/* effects NAV */

	/* Effect 21: borders slight translate */
		.effect a {
			padding: 10px;
			color: #237546;
			font-weight: 700;
			text-shadow: none;
			-webkit-transition: color 0.3s;
			-moz-transition: color 0.3s;
			transition: color 0.3s;
		}

		
		.effect a::after {
			position: absolute;
			left: 0;
			width: 100%;
			height: 2px;
			background: $orange;
			content: '';
			opacity: 0;
			-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
			-moz-transition: opacity 0.3s, -moz-transform 0.3s;
			transition: opacity 0.3s, transform 0.3s;
			-webkit-transform: translateY(-10px);
			-moz-transform: translateY(-10px);
			transform: translateY(-10px);

			@include breakpoint (sm) {
				height: 0px;
			}
		}

		.effect a::after {
			bottom: -2px;
			-webkit-transform: translateY(10px);
			-moz-transform: translateY(10px);
			transform: translateY(10px);
		}

		.effect a:hover,
		.effect a:focus,
		.effect a:active {
			color: #fff;
		}

		.effect a:hover::before,
		.effect a:focus::before,
		.effect a:hover::after,
		.effect a:focus::after  {
			opacity: 1;
			-webkit-transform: translateY(0px);
			-moz-transform: translateY(0px);
			transform: translateY(0px);
		}
		
}


