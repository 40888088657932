// TRANSITIONS

@mixin transition($transition, $time){
  transition: $transition $time;
  -webkit-transition: $transition $time;
  -moz-transition: $transition $time;
  -ms-transition: $transition $time;
  -o-transition: $transition $time;
}

// BREAKPOINTS

@mixin respond-to($breakpoint) {
  @media only screen and (min-width: $breakpoint + px) { @content; }
}

@mixin breakpoint($class) {
  @if $class == xxs {
    @media (max-width: 555px) { @content; }
  }

  @if $class == xs {
    @media (max-width: 767px) { @content; }
  }

  @else if $class == sm {
    @media (max-width: 992px) { @content; }
  }

  @else if $class == md {
    @media (max-width: 1200px) { @content; }
  }

  @else if $class == lg {
    @media (max-width: 1440px) { @content; }
  }

  @else if $class == xlg {
    @media (min-width: 1441px) { @content; }
  }
}

// CENTER BLOCK

@mixin center($centerY, $centerX){
  transform: translate($centerY, $centerX);
  -webkit-transform: translate($centerY, $centerX);
  -moz-transform: translate($centerY, $centerX);
  -ms-transform: translate($centerY, $centerX);
  -o-transform: translate($centerY, $centerX);
}
@mixin centerY($centerY){
  transform: translateY($centerY);
  -webkit-transform: translateY($centerY);
  -moz-transform: translateY($centerY);
  -ms-transform: translateY($centerY);
  -o-transform: translateY($centerY);
}
@mixin centerX($centerX){
  transform: translateX($centerX);
  -webkit-transform: translateX($centerX);
  -moz-transform: translateX($centerX);
  -ms-transform: translateX($centerX);
  -o-transform: translateX($centerX);
}
