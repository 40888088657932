
/* ---------------------------------------------------------
	Section
---------------------------------------------------------*/

section.home {

	min-height:100%;
	position: relative;

	.container {

		.row {
			margin: 60px 0;
			padding-top: 60px;

			@include breakpoint (xs) {
				margin: 0 auto;
			}
		}


	 }


	figure {
		position: relative;
		overflow: hidden;
		max-width: 272px;
		height: 500px;

		@include breakpoint (sm) {
			height: 300px;
			margin-bottom: 20px;
			width: 100%;
			max-width: 100%;
		}
	}

	figure img {
		position: absolute;
		display: block;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		-webkit-transform: translate(-50%,-50%);
		-moz-transform: translate(-50%,-50%);
		-ms-transform: translate(-50%,-50%);
	}

	.last-bg {

		@include breakpoint (sm) {
			top: 20%;
		}

	}

	figure figcaption {
		padding: 2em;
		color: #fff;
		text-transform: uppercase;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
	}

	figure figcaption::before,
	figure figcaption::after {
		pointer-events: none;
	}

	figure figcaption,
	figure figcaption > a {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	/* Anchor will cover the whole item by default */
	/* For some effects it will show as a button */
	figure figcaption > a {
		z-index: 1000;
		text-indent: 200%;
		white-space: nowrap;
		font-size: 0;
		opacity: 0;
	}

	/* Individual effects */

	/*---------------*/
	/***** Effect *****/
	/*---------------*/

	figure.effect-img img {
		max-width: none;
		height: 500px;
		-webkit-transition: opacity 1s, -webkit-transform 1s;
		transition: opacity 1s, transform 1s;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
	}

	figure.effect-img figcaption {
		text-align: left;
		background-color: rgba(57,123,173, 0.2);
	}

	figure.effect-img h4{
		position: relative;
		padding: 0.5em 0;

		@include breakpoint (xs) {
			font-size: 30px;
		}
	}

	figure.effect-img span {
		display: inline-block;
	    position: absolute;
	    top: 55px;
	    right: 20px;
	    padding: 2px 8px;
	    color: white;
	    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	    transition: opacity 0.35s, transform 0.35s;
	    -webkit-transform: translate3d(-360px, 0, 0);
	    transform: translate3d(-360px, 0, 0);
	    border: 1px solid white;
	    borer-radius: 30px;
	    border-radius: 30px;

	    @include breakpoint (xs) {
	    	display: none;
	    }
	}

	figure.effect-img p {
		display: inline-block;
		margin: 0 0 0.25em;
		padding: 0.4em 1em;
		color: white;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 14px;
		letter-spacing: 1px;
		-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
		transition: opacity 0.35s, transform 0.35s;
		-webkit-transform: translate3d(-360px,0,0);
		transform: translate3d(-360px,0,0);

		@include breakpoint (xs) {
			transform: none;
		}
	}

	figure.effect-img p:first-child {
		-webkit-transition-delay: 0.15s;
		transition-delay: 0.15s;
	}

	figure.effect-img p:nth-of-type(2) {
		-webkit-transition-delay: 0.1s;
		transition-delay: 0.1s;
	}

	figure.effect-img p:nth-of-type(3) {
		-webkit-transition-delay: 0.05s;
		transition-delay: 0.05s;
	}

	figure.effect-img:hover p:first-child {
		-webkit-transition-delay: 0s;
		transition-delay: 0s;
	}

	figure.effect-img:hover p:nth-of-type(2) {
		-webkit-transition-delay: 0.05s;
		transition-delay: 0.05s;
	}

	figure.effect-img:hover p:nth-of-type(3) {
		-webkit-transition-delay: 0.1s;
		transition-delay: 0.1s;
	}

	figure.effect-img:hover {
		box-shadow: 0 3px 30px rgba(0,0,0,0.2);
		transition: all 0.5s ease;
	}

	figure.effect-img:hover img {
		box-shadow: 0 3px 30px rgba(0,0,0,0.2);
		-webkit-transform: translate(-50%,-50%) scale3d(1.1,1.1,1);
		transform: translate(-50%,-50%) scale3d(1.1,1.1,1);
	}

	figure.effect-img:hover p {
		opacity: 1;
		-webkit-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0);
	}
	figure.effect-img:hover span {
		opacity: 1;
		-webkit-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0);


	}

	figure.effect-img:hover figcaption {
		background-color: rgba(49,103,144, 0.8);
		transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
	}
}




.wrapper {
	.container {
		position: relative;
		z-index:2;
	}
}

/* --------------------------------------------------------
   Particles 
-----------------------------------------------------------*/

/* ---- stats.js ---- */

.count-particles{
  background: #000022;
  position: absolute;
  top: 48px;
  left: 0;
  width: 80px;
  color: #13E8E9;
  font-size: .8em;
  text-align: left;
  text-indent: 4px;
  line-height: 14px;
  padding-bottom: 2px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
}

.js-count-particles{
  font-size: 1.1em;
}

#stats,
.count-particles{
  -webkit-user-select: none;
  margin-top: 5px;
  margin-left: 5px;
}

#stats{
  border-radius: 3px 3px 0 0;
  overflow: hidden;
}

.count-particles{
  border-radius: 0 0 3px 3px;
}


/* ---- particles.js container ---- */

#particles-js{
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1;

}


/* --------------------------------------------------------
	Internas
---------------------------------------------------------*/

.page-header {

	position: relative;
	border-bottom:0px;
	margin-top: 80px;
	z-index:2;

	@include breakpoint (sm) {
		margin:0;
		padding:0px;
	}

	.container {
		height:400px;

		h1 {
			color: white;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.empresas {
		background-image: url(../images/page-header-empresas.jpg);
		background-position: center;
		background-repeat: no-repeat;
	}
	.empresa {
		background-image: url(../images/page-header-empresa.jpg);
		background-position: center;
		background-repeat: no-repeat;
	}
	.residencial {
		background-image: url(../images/page-header-residencial.jpg);
		background-position: center;
		background-repeat: no-repeat;
	}
	.cloud {
		background-image: url(../images/page-header-cloud.jpg);
		background-position: center;
		background-repeat: no-repeat;
	}
	.soluciones {
		background-image: url(../images/page-header-it.jpg);
		background-position: center;
		background-repeat: no-repeat;
	}
	.seguridad {
		background-image: url(../images/page-header-seguridad.jpg);
		background-position: center;
		background-repeat: no-repeat;
	}
	.contacto {
		background-image: url(../images/page-header-contacto.jpg);
		background-position: center;
		background-repeat: no-repeat;
	}

}

/* --------------------------------------------------------
	Blocks
---------------------------------------------------------*/

.block {

	margin-bottom: 20px;
	z-index:2;
	position:relative;

	.container {
		padding:40px;
	}

	.no-pad {
		padding: 0 15px;
	}

	.cont-collapse {
		padding:0px 40px!important;
	}

}

.form-pad {
	padding: 40px;
}

.center {
	text-align: center;
}

.justify {
	text-align: justify;
}

.block-light-blue {
	background-color: $light-blue;
	color:white;
	padding: 30px;
	width: 49%!important;
	margin-right: 15px;
	
	@include breakpoint (xs) {
		width: 100%!important;
		margin-bottom: 20px;
	}

	h4 {
		@include breakpoint (md) {
			font-size: 25px;
		}
		@include breakpoint (sm) {
			font-size: 19px;
		}
	}
}

.block-blue {
	background-color: $light-blue;
	color:white;
	padding: 30px;
	width: 49%!important;
	float: right!important;


	@include breakpoint (xs) {
		width: 100%!important;
	}

	h4 {
		@include breakpoint (md) {
			font-size: 25px;
		}
		@include breakpoint (sm) {
			font-size: 19px;
		}
	}
}

.block-levels {

	h3 {
		color: $orange;

		span {
			color:$text;

		}
		@include breakpoint (sm) {
			margin-bottom: 20px;
		}
	}

	p {
		color:$text;
		line-height: 1.5;
	}

}



/* --------------------------------------------------------
	Gray BG Blocks
---------------------------------------------------------*/

.gray {

	background-color:$gray;
	padding:0px!important;
	overflow: hidden;

	p {
		color:white;
		line-height: 1.5;
		text-align: justify;
		padding:40px 25px 40px 40px;

		@include breakpoint (md) {
			font-size: 14px;
		}

		@include breakpoint (sm) {
			padding: 40px;
			font-size: 14px;
		}
	}

	.hightlight {
		color: $orange;
	}

	.bg-empresa-img {
		background-image: url(../images/empresa-img.jpg);
	    background-position: top;
	    background-repeat: no-repeat;
	    height: 760px;
	    overflow: hidden;
	    padding: 0px;
	    background-size: cover;

	    @include breakpoint (sm) {
			height: 400px;
		}
	}

	.bg-stairs {
		background-image: url(../images/stairs.jpg);
	    background-position: top;
	    background-repeat: no-repeat;
	    height: 760px;
	    overflow: hidden;
	    padding: 0px;
	    background-size: cover;

	    @include breakpoint (sm) {
			height: 400px;
		}
	}
	.bg-red {
		background-image: url(../images/red.jpg);
	    background-position: top;
	    background-repeat: no-repeat;
	    height: 379px;
	    overflow: hidden;
	    padding: 0px;
	    background-size: cover;

	    @include breakpoint (sm) {
			height: 400px;
		}
	}

	 .plus {
 		list-style: none;
 		color:white;
		line-height: 1.5;
		text-align: justify;
		padding:0 20px 0 40px;

		@include breakpoint (sm) {
			padding: 0 40px 30px 40px;
		}

 		li {
 			padding: 10px 0;

 			@include breakpoint (md) {
			    padding: 5px 0;
			    font-size: 14px;
			    letter-spacing: -0.5px;
 			}

 		}



 		.ion-ios-checkmark-empty {
 			color: $orange;
 			padding-right: 5px;
 			font-size: 16px;
 		}	

 	}

	 .data {

	 		padding: 40px;

	    .box {

	        @include breakpoint (md) {
	          padding: 0px;
	        }
	        @include breakpoint (sm) {
	          text-align: center;
	        }

	      a {
	        color: $text;
	        &:hover {
	          color: $orange;
	          text-decoration: none;
	        }

	      }

	      p {
	        font-size: 14px;
	        line-height: 1.5;
	        color: $text;
	        padding: 0 0 20px 0;

	          a {
	            color: $text;
	            font-size: 14px;
	            font-weight: 300;
	            &:hover {
	              color: $orange;
	              text-decoration: none;
	              font-weight: 300;
	            }
	          }

	        @include breakpoint (sm) {
	          text-align: center!important;
	        }
	      }

	      i {
	        color: $text;
	        font-size: 30px;
	        transition: all 0.5s ease;
	        vertical-align: middle;

	      }

	    }
	  }

}

/* --------------------------------------------------------
	Orange BG Blocks
---------------------------------------------------------*/

.orange {
	background-color:$orange;
	color: white;

 	h1 {
 		color: white;
 	}

 	p {
 		color: white;
 	}

	.features {

		padding-left: 0px!important;
		list-style: none;
		

		li {
			border-bottom:1px solid white;
			padding: 10px 0;

			p {
				color: white;
				text-align: justify;
				margin: 0;
			}
		}
		
	}

	.middle {
		padding: 40px 15px;
	}
}

/* --------------------------------------------------------
	White BG Blocks
---------------------------------------------------------*/

.light {
	background-color: #f6f6f6;
}


.white {
	background-color:white;

	h2 {
 		color: $orange;
 		margin-bottom: 40px;

 		@include breakpoint (sm) {
 			margin-bottom: 20px;
 		}
 	}

 	.plus {
 		list-style: none;

 		li {
 			padding: 10px 0;
 		}

 		.ion-ios-checkmark-empty {
 			color: $orange;
 			padding-right: 5px;
 			font-size: 16px;
 		}
 		.ion-plus {
 			color: $orange;
 			padding-right: 5px;
 			font-size: 16px;
 		}


 		.margin-list {
		  margin-left: 17px!important;

		  @include breakpoint (sm) {
			margin-left: 0px!important;
		  }
		}
 	}

 	.no-padding {
 		padding-left:0px!important;
 	}

 	.short {
 		@include breakpoint (xs) {
 			li {
 				padding: 5px 0;
 			}
 		}
 	}

 	.collapse-block {

 		h2 {
 			margin-bottom: 0px;
 		}
 	}

 	.collapse.in {
    	padding: 30px 5px;
    	transition: all 0.5s ease;
	}

 	#collapse-cober {

 		li {
 			color: $text;
 			text-transform: none;
 		}

 	 .legal-txt {
 	 	font-size: 12px;
 	 	padding-bottom:30px;
 	 }	

 	}

}


/* --------------------------------------------------------
	TABS
---------------------------------------------------------*/



.bhoechie-tab {
	background-color: white;
}

.tab-header-img {
	position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    max-width: 100%;
    height: 250px;

    img {

	    display: block;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%, -50%);
	    -webkit-transform: translate(-20%, -50%);
    }

    @include breakpoint (xs) {
    	display: none;
    }
}

div {
  &.bhoechie-tab-container {
    z-index: 10;
    padding: 0 !important;
  }
  &.bhoechie-tab-menu {
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    div.list-group {
      margin-bottom: 0;
      > a {
        margin-bottom: 0;

        &:first-child {
          border-top-right-radius: 0;
          -moz-border-top-right-radius: 0;
        }
        &:last-child {
          border-bottom-right-radius: 0;
          -moz-border-bottom-right-radius: 0;
        }
        &.active {
          background-color: $light-blue;
          background-image: none;
          color: #ffffff;

          &:after {
            content: '';
            position: absolute;
            left: 100%;
            top: 50%;
            margin-top: -13px;
            border-left: 0;
            border-bottom: 13px solid transparent;
            border-top: 13px solid transparent;
            border-left: 10px solid $light-blue;
          }
        }
      }
    }
  }
  &.bhoechie-tab-content {
    background-color: #ffffff;
    padding: 40px;
    color:$text;
    line-height: 1.5;
    text-align: justify;

    .tab-title {
    	color: $text;
    }

    .price {
    	font-size: 12px;
    }

    .inner {
    	margin-top: 190px;
    	margin-bottom: 40px;

    	@include breakpoint (xs){
    		margin-top: 10px;
    		margin-bottom: 20px;
    	}
    }

    .plus {
 		.ion-ios-checkmark-empty {
 			color: $orange;
 			padding-right: 5px;
 			font-size: 20px;
 		}

 	}

    .tab-title-orange {
    	color: $orange;
    }

    @include breakpoint (xs) {
		padding: 20px;
	}

    .features-white {
		list-style: none;
		margin-top: 20px;
		width: 350px;
		padding-left: 0px!important;

		@include breakpoint (sm) {
			width: 100%;
		}

		li {
			border-bottom:1px solid $text;
			padding: 10px 0;

			p {
				color: $text;
				text-align: justify;
				margin: 0;

				@include breakpoint (xs) {
					font-size: 12px;
					text-align: left;
				}
			}
		}
	

	}

	.large {
		width: 550px;
		@include breakpoint (sm) {
			width: 100%;
		}
	}
  }
  &.bhoechie-tab div.bhoechie-tab-content:not(.active) {
    display: none;
  }

	.list-group-item {
		padding: 50px 0px;
		background-color: $dark-blue;
    	border: 0px;
    	color: #ffffff;

    	&:hover {
			padding: 50px 0px;
			background-color: #89b4ed;
	    	border: 0px;
	    	color: #ffffff;
		}

		&:focus {
			padding: 50px 0px;
			background-color:#89b4ed;
	    	border: 0px;
	    	color: #ffffff;
		}

		h4 {

			@include breakpoint (xs) {
				font-size: 16px;
			}
		}

	}

	.list-group-item.active {
		text-shadow: none;
		background-image: none;
		border-color: transparent;
		border: 0px;

		&:hover {
			text-shadow: none;
			background-image: none;
			background-color: $dark-blue;
	    	border: 0px;
	    	color: #ffffff;
		}

		&:focus {
			text-shadow: none;
			background-image: none;
			background-color: $dark-blue;
	    	border: 0px;
	    	color: #ffffff;
		}

	}
	.list-group-item:first-child {
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
	}
}


/* --------------------------------------------------------
	OWL
---------------------------------------------------------*/

 #owl-brands {

 	.owl-stage-outer {
 		padding:0 60px;
 		@include breakpoint (sm) {
 			padding: 0 40px;
 		}
 		@include breakpoint (xs) {
 			padding: 0 40px;
 		}
 	}

 	.owl-nav {

  	  position: absolute;
      top: 30%;
      width: 100%;

      @include breakpoint (xs) {
      	top:0%;
      }

      .owl-prev {
        display: inline-block;
        margin-right: 16px;
        font-size: 40px;
        color: $gray;
        transition: all 0.5s ease;

        &:hover {
          color: $orange;
          transition: all 0.5s ease;
        }

      }

      .owl-next {
        display: inline-block;
        font-size: 40px;
        color: $gray;
        transition: all 0.5s ease;
        position:absolute;
        right: 0%;


        &:hover {
          color: $orange;
          transition: all 0.5s ease;
        }

      }
		       	
 	}
 	img {
 		width: auto;
 	}
 	.img-brand {
 		height:90px;
 		max-width: 100%;

 		@include breakpoint (sm) {
 			height: 50px;
 		}
 		@include breakpoint (xs) {
 			height: 50px;
 		}
 	}
 }