footer {

	background:$dark-gray;
	padding: 40px 0;
	position:relative;
	z-index: 3;

	@include breakpoint (sm) {
    	padding: 40px 0 0 0;
	}
	
	p {
		font-family: 'Open Sans', sans-serif;
		color: #6f6e71;
		font-size: 12px;
		font-weight: 400;
		text-transform: uppercase;
		line-height: 1.5;
		border-right: 1px solid #6f6e71;
		letter-spacing: 1px;
		margin-top:15px;

		a {
			color: #6f6e71;
			text-transform: uppercase;
			font-family: 'Open Sans', sans-serif;
			font-size: 12px;
			font-weight: 400;
			transition: all 0.5s ease;

			@include breakpoint (md) {
			 font-size: 11px;
			}

			 &:hover {
			 	text-decoration: none;
			 	color: $light-blue;
			 	transition: all 0.5s ease;
			 }
		}

		@include breakpoint (sm) {
    		text-align: center;
    		margin-top:25px;
    	}

    	@include breakpoint (xs) {
    		border-right:0px;
    	}	

    	@include breakpoint (xxs) {
    		margin-top:0px;
    		margin-bottom: 10px;
    		border-right:0px;
    	}
	}

	img {
		max-width: 150px;

		@include breakpoint (sm) {
			margin: 0 auto;
		    display: block;
		    margin-bottom: 20px;

		}
	}

	.no-pad {
		@include breakpoint (md) {
			padding:0px;
		}
	}

	.mails {
		p {
			padding-left:15px;

			@include breakpoint (sm) {
			padding: 0px;
			}
		}
	}

	.footer-nav {

		padding: 25px 0;

		@include breakpoint (md) {
			padding: 22px 0px;
		}

		@include breakpoint (sm) {
    		text-align: center;
    		margin-top:40px;
    		border-top: 1px solid #6f6e71;
    		border-bottom: 1px solid #6f6e71;
		}

		li {
			display: inline;
			padding: 0 10px;

			@include breakpoint (md) {
				padding: 0 6px;
			}

			a {
				color: #6f6e71;
				text-transform: uppercase;
				font-family: 'Open Sans', sans-serif;
				font-size: 12px;
				font-weight: 400;
				transition: all 0.5s ease;

				@include breakpoint (md) {
				 font-size: 11px;
				}

				 &:hover {
				 	text-decoration: none;
				 	color: $light-blue;
				 	transition: all 0.5s ease;
				 }
			}
		}
	}

}


.rights {

	background:$light-gray;
	padding: 20px 0;
	position: relative;
	z-index: 50;

	p {
		font-family: 'Open Sans', sans-serif;
		color: $footer;
		font-size: 12px;
		font-weight: 400;
	}

	
	@include breakpoint (sm) {
		text-align: center;
		img {
			margin: 20px 0;
		}
	}


  .social {
    color: white;
    text-align: left;

    @include breakpoint (sm) {
      padding: 20px;
      text-align: center;
    }

    i {
      color: $footer;
      padding: 0 5px;
      transition: all 0.5s ease;

      &:hover {
        color: $orange;
        transition: all 0.5s ease;
      }
    } 

  }
    .ion-chevron-up {
    	background-color: #000000;
		padding: 8px 10px;
		font-size: 10px;
    }  
}







