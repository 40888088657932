
$text: #6b6b6b;
$gray: #212123;
$dark-gray: #141414;
$light-gray: #1c1c1c;
$orange: #ff6f42;
$dark-blue: #6a97d1;
$light-blue:#76a6e5;
$btn:#5780b3;
$footer:#777777;
$nav: #316790;


$spacing: 2560px;
$time-1: 60s;
$time-2: 120s;
$time-3: 180s;
$time-4: 600s;



