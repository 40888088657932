/* ==========================================================================
   Btn More 
   ========================================================================== */

	a {
		font-weight: 700;
		color:white;
		text-transform: uppercase;
		text-decoration: none;
		border:0px;

		&:hover {
			transition: all 0.5s ease;
			color:white;
			text-decoration: none;
			border:0px;

		}

		&:focus {
			transition: all 0.5s ease;
			color:white;
			text-decoration: none;

		}

	}

	.btn-send {

		float: right;
		color: $btn;
		font-weight: 600;
		font-family: 'Roboto', sans-serif;
		letter-spacing: 1px;
		background: transparent;
		border-radius:30px;
		border:1px solid $btn;
    	padding: 10px 50px;
    	transition: all 0.5s ease;

		&:hover {

			color: white;
			background: $btn;
			border:1px solid $btn;
			transition: all 0.5s ease;
			text-decoration: none;
		}

		@include breakpoint (xs) {
			float: none;
			margin: 0 auto;
    		display: block;
		}

	}


.collapse-btn {

	color: $orange!important;

	.btn-icon {
		font-size: 40px;
	}

	.white-icon {
		color: white!important;
	}
	
}





