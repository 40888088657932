/* Welcome to Compass.
 * In this file you should write your main styles. (or centralize your imports)
 * Import this file using the following HTML or equivalent:
 * <link href="/stylesheets/screen.css" media="screen, projection" rel="stylesheet" type="text/css" /> */
 $fa-font-path: '../fonts/' !default;

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap.scss";
@import "node_modules/font-awesome-sass/assets/stylesheets/font-awesome.scss";


@import "node_modules/ionicons/scss/ionicons.scss";

@import "node_modules/animate-scss/animate.scss";

@import "components/scrolling-nav";

@import "helpers/variables";
@import "helpers/helpers";

@import "layout/fonts";
@import "layout/mixins";
@import "layout/basic";


@import "components/buttons";
@import "components/modals";

@import "modules/header";
@import "modules/footer";
@import "modules/alertas";

@import "pages/home";
