
/** Generated by FG **/
@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');


/** Generated by FG **/
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900');


/* FontAwesome
 * -------------------------- */
@font-face {
  font-family: 'FontAwesome';
  src: url('../../fonts/fontawesome-webfont.eot?v=4.6.3');
  src: url('../../fonts/fontawesome-webfont.eot?#iefix&v=4.6.3') format('embedded-opentype'), url('../../fonts/fontawesome-webfont.woff2?v=4.6.3') format('woff2'), url('../../fonts/fontawesome-webfont.woff?v=4.6.3') format('woff'), url('../../fonts/fontawesome-webfont.ttf?v=4.6.3') format('truetype'), url('../../fonts/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* ION ICONS
 * -------------------------- */

@font-face {
  font-family: "Ionicons";
  src: url("../../fonts/ionicons.eot?v=2.0.0");
  src: url("../../fonts/ionicons.eot?v=2.0.0#iefix") format("embedded-opentype"), url("../../fonts/ionicons.ttf?v=2.0.0") format("truetype"), url("../../fonts/ionicons.woff?v=2.0.0") format("woff"), url("../../fonts/ionicons.svg?v=2.0.0#Ionicons") format("svg");
  font-weight: normal;
  font-style: normal;
}
