/*!
 * Start Bootstrap - Scrolling Nav HTML Template (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

body {
    width: 100%;
    height: 100%;
}

html {
    width: 100%;
    height: 100%;
}

@media(min-width:767px) {
    /*.navbar {
        padding: 20px 0;
        -webkit-transition: background .5s ease-in-out,padding .5s ease-in-out;
        -moz-transition: background .5s ease-in-out,padding .5s ease-in-out;
        transition: background .5s ease-in-out,padding .5s ease-in-out;
    }*/

    .top-nav-collapse {
        padding: 0;
    }
}

/* Demo Sections - You can use these as guides or delete them - the scroller will work with any sort of height, fixed, undefined, or percentage based.
The padding is very important to make sure the scrollspy picks up the right area when scrolled to. Adjust the margin and padding of sections and children
of those sections to manage the look and feel of the site. */

.intro-section {
    height: 100%;
    padding-top: 150px;
    text-align: center;
    background: #fff;
}

.about-section {
    height: 100%;
    padding-top: 150px;
    text-align: center;
    background: #eee;
}

.services-section {
    height: 100%;
    padding-top: 150px;
    text-align: center;
    background: #fff;
}

.contact-section {
    height: 100%;
    padding-top: 150px;
    text-align: center;
    background: #eee;
}
