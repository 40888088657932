body {
	
	font-family: 'Lato', sans-serif;
	font-weight:400;
	font-size: 16px;
	background: rgb(49,103,144); /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMzE2NzkwIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzNlODdiZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
	background: -moz-linear-gradient(-45deg, rgb(49,103,144) 0%, rgb(62,135,191) 100%); /* FF3.6-15 */
	background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,rgb(49,103,144)), color-stop(100%,rgb(62,135,191))); /* Chrome4-9,Safari4-5 */
	background: -webkit-linear-gradient(-45deg, rgb(49,103,144) 0%,rgb(62,135,191) 100%); /* Chrome10-25,Safari5.1-6 */
	background: -o-linear-gradient(-45deg, rgb(49,103,144) 0%,rgb(62,135,191) 100%); /* Opera 11.10-11.50 */
	background: -ms-linear-gradient(-45deg, rgb(49,103,144) 0%,rgb(62,135,191) 100%); /* IE10 preview */
	background: linear-gradient(135deg, rgb(49,103,144) 0%,rgb(62,135,191) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#316790', endColorstr='#3e87bf',GradientType=1 ); /* IE6-8 fallback on horizontal gradient */
    background-repeat: no-repeat;
    height: auto;

}


html {
	height: auto;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Lato', sans-serif;
	line-height: 1;
	text-transform: uppercase;

}

h1 {
	font-size: 60px;
	font-weight: 300;
    letter-spacing: -2px;
	text-transform: none;
	color:$orange;

	@include breakpoint (sm) {
		font-size: 40px;
	}

	@include breakpoint (xs) {
		font-size: 40px;
	}

	span {
		font-size: 50px;

		@include breakpoint (sm) {
		font-size: 40px;
		}

		@include breakpoint (xs) {
			font-size: 40px;
		}
	}

}

h2 {
	font-size: 40px;
	font-weight: 300;
    letter-spacing: -2px;
	text-transform: none;

	span {
		font-size: 60px;

		@include breakpoint (sm) {
		font-size: 40px;
		}

		@include breakpoint (xs) {
			font-size: 25px;
		}
	}

	
	@include breakpoint (sm) {
		font-size: 35px;
	}

	@include breakpoint (xs) {
		font-size: 30px;
	}
}

h3 {
	font-size: 40px;
	font-weight: 300;
    letter-spacing: -1px;
	text-transform: none;

	@include breakpoint (sm) {
		font-size: 30px;
	}

	@include breakpoint (xs) {
		font-size: 30px;
	}
}

h4 {
	font-size: 30px;
	font-weight: 300;
    letter-spacing: -1px;
	text-transform: none;

	@include breakpoint (sm) {
		font-size: 25px;
	}

	@include breakpoint (xs) {
		font-size: 25px;
	}
}

h5 {
	font-size: 20px;
	font-weight: 300;
    letter-spacing: -1px;
	text-transform: none;

}

p {
	color: $text; 
}


.strong {
	font-weight:700;
}


ul {
	list-style: none!important;
}

/* ---------------------------------------------------------
	Form
---------------------------------------------------------*/

form {

	.form-group {
		margin-bottom: 0px;

		label {

			font-weight: 300;
			text-transform: uppercase;
			line-height: 1.3;
			color: #333333;
			font-size: 13px;
			padding-bottom:10px;
			letter-spacing: 1px;

			@include breakpoint (xs) {
	  			font-size: 12px;
	  			color: $gray;
	  			font-weight: 400;
			} 

		}

		input {

			font-family: 'Montserrat', sans-serif;
			font-size: 12px;
			font-weight: 100;
			letter-spacing: 2px;
			padding-left:15px;
			border: 0px;
			background-image: none;
			outline: none;
			box-shadow: none;
			margin: 10px 0;
			border-bottom: 1px solid #ccc;
			transition: all 0.5s ease;

			&:focus {
				color: $btn!important;
				border-bottom: 1px solid $btn;
				transition: all 0.5s ease;
				box-shadow: none!important;
			}

		}

		textarea {

			font-family: 'Montserrat', sans-serif;
			font-size: 12px;
			font-weight: 100;
			letter-spacing: 2px;
			padding-left:15px;
			border: 0px;
			background-image: none;
			outline: none;
			box-shadow: none;
			margin: 10px 0;
			border-bottom: 1px solid #ccc;
			transition: all 0.5s ease;
			height: 130px;
			
			&:focus {
				color: $btn!important;
				border-bottom: 1px solid $btn;
				transition: all 0.5s ease;
				box-shadow: none!important;
			}

		}


		.select {
		  position: absolute;
		  width: 150px;

		  	@include breakpoint (sm) {
		    	position: relative;
		    	width: 100%;
		    }
		  
			select {

				font-family: 'Montserrat', sans-serif;
				font-size: 12px;
				font-weight: 100;
				letter-spacing: 2px;
				padding-left:30px;
				border: 0px;
				background-image: none;
				outline: none;
				box-shadow: none;
				margin: 10px 0;
				border-bottom: 1px solid #ccc;
				transition: all 0.5s ease;
				appearance: none;
			    -moz-appearance: none;
			    -webkit-appearance: none;
			    width: 330px;

			    @include breakpoint (md) {
			    	width: 260px;
			    }
			    @include breakpoint (sm) {
			    	width:100%;
			    }

				&:focus {
					color: $btn!important;
					border-bottom: 1px solid $btn;
					transition: all 0.5s ease;
					box-shadow: none!important;
				}

			}
		  
		  .select-side {

		    &:before {
		      content : "";
		      position: absolute;
		      left    : 0;
		      bottom  : 0;
		      height  : 100%;
		      width   : 1px;  /* or 100px */
		    }
		    
		    position: absolute;
		    top: 15px;
		    height: 100%;
		    right: -160px;
		    pointer-events: none;

		    @include breakpoint (md) {
		    	right: -80px;
		    }
		    @include breakpoint (sm) {
		    	right: 8px;
		    	top:5px;
		    }

		    i {
		      color:$btn;
		    }
 
		  }
		}

		@include breakpoint (xs) {
	  	   padding: 0px;
	  	   margin-bottom:15px;
	  
		}

		.required {
			font-size: 11px!important;
			letter-spacing: 1px;
		} 
	}

	.errorField {
		color: #ea3e1c;
		font-weight: 700;
		margin-top: 20px;
	}

	.alert-success {
	    color: white;
	   	background: #316790;
	    border-color: transparent;
	    margin-top: 20px;
	}
}

/* ---------------------------------------------------------
	Placeholder  
---------------------------------------------------------*/


	::-webkit-input-placeholder {
		font-family: 'Montserrat', sans-serif;
		font-size: 12px;
		font-weight: 100;
		letter-spacing: 2px;
		padding-left:15px;
		text-transform: uppercase; 
	}

	:-moz-placeholder { /* Firefox 18- */
	    font-family: 'Montserrat', sans-serif;
		font-size: 12px;
		font-weight: 100;
		letter-spacing: 2px;
		padding-left:15px;
		text-transform: uppercase; 	
	}

	::-moz-placeholder {  /* Firefox 19+ */
	    font-family: 'Montserrat', sans-serif;
		font-size: 12px;
		font-weight: 100;
		letter-spacing: 2px;
		padding-left:15px;
		text-transform: uppercase; 
	}

	:-ms-input-placeholder {  
	    font-family: 'Montserrat', sans-serif;
		font-size: 12px;
		font-weight: 100;
		letter-spacing: 2px;
		padding-left:15px;
		text-transform: uppercase; 
		
	}

	input:focus::-webkit-input-placeholder {
       color: $btn;
    }

    input:focus:-moz-placeholder { /* Firefox 18- */
       color: $btn;
    }

    input:focus::-moz-placeholder {  /* Firefox 19+ */
       color: $btn;
    }

    input:focus:-ms-input-placeholder {
       color: $btn;
    }
    textarea:focus::-webkit-input-placeholder {
       color: $btn;
    }

    textarea:focus:-moz-placeholder { /* Firefox 18- */
       color: $btn;
    }

    textarea:focus::-moz-placeholder {  /* Firefox 19+ */
       color: $btn;
    }

    textarea:focus:-ms-input-placeholder {
       color: $btn;
    }


	
/* ---------------------------------------------------------
	Map
---------------------------------------------------------*/


.map-container {
    padding: 10px 0 40px 0;
    
}

#myMap {
    width: inherit;
    height: inherit;
    height: 340px;

     @include breakpoint(xs){
      height: 200px;
    }


}